//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'ModalBase',

  props: {
    enterAnim: { type: String, default: 'fadeInDown' },
    leaveAnim: { type: String, default: 'fadeOutUp' }
  },

  data() {
    return {
      isActive: false
    };
  },

  methods: {
    show() {
      this.isActive = true;
    },

    hide() {
      this.isActive = false;
    },

    onBgClick() {
      this.hide();
    },

    onBgFadeAnimAfterLeave() {
      this.$emit('hide');
    }
  }
};
